import indexOf from 'lodash/indexOf';

export const canonicalizeDataString = (param) => {
  var canonical;
  if (param) {
    canonical = decodeURIComponent(param);
    canonical = canonical.toLowerCase();
    canonical = canonical.replace('amp;', '').replace(/\W/g, '');
  }
  return canonical;
};

const _validConditions = ['new', 'used'];
export const isValidCondition = (condition) => {
  return condition && indexOf(_validConditions, condition) !== -1;
};

export const getPageAdsData = (config, adsData) => {
  return Object.keys(config).reduce((acc, key) => {
    const zoneID = config[key].zoneID;
    acc[key] = { ...config[key] };
    if (adsData && adsData[zoneID]) {
      acc[key].placement = adsData[zoneID].placements?.placement_1;
    }
    return acc;
  }, {});
};
