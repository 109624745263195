import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import merge from 'lodash/merge';
import { Ad } from '@dmm/react-common-components';
import adParams from './resources/adParams';
import { adsConfig, getTargeting } from './resources/adsConfig';
import AdProvider from '../../../components/Ads/AdProvider';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setProduct,
  setProductImpression
} from '../../../store/actions/dataLayer';
import Meta from '../../../components/Meta';
import * as meta from '../utils/meta';
import { injectIntl } from 'react-intl';
import { BREAKPOINTS } from '../../../utils/commonHelper';
import QuickSearch from '../../../components/QuickSearch';
import HeroViewer from '../../../components/HeroViewer';
import Link from '../../../components/SEO/Link';
import BoatList, { pageSizes } from '../../../components/BoatList';
import EditorialItem from '../../../components/EditorialItem';
import QuickLinks from '../QuickLinks';
import FSBOAd from '../FSBOAd';
import { PortalConfigContext } from '../../../config/portal';
import { getBreakpoint } from '../../../utils/commonHelper';
import {
  listingPropTypes,
  privateFeaturedAdTypes
} from '../../../utils/commonPropTypes';
import {
  featuredBoatsProps,
  getLocation,
  getPrice,
  getTitle
} from '../../../utils/listingHelper';
import { generateSearchPath } from '../../../utils/urlHelpers/boats';
import {
  getCurrentLocale,
  getPortalName
} from '../../../utils/language';
import { editContentAnchors } from '../utils/utils';
import './styles.css';
import HomeBlogSection from '../../../components/HomeBlogSection/index.js';
import HomeForumSection from '../../../components/HomeForumSection';
import SocialMedia from '../../../components/SocialMedia';
import classnames from 'classnames';
import { getCustomUom } from '../../../utils/uomHelper';
import { getCanonical } from '../../../utils/seo';
import FeaturedBoats from '../../../components/FeaturedBoats';
import { Cookies } from 'react-cookie';
import HomeBuyingExperience from '../../../components/HomeBuyingExperience';
import PrivateFeaturedAds from '../../../components/ListingCarousel';
import HomeSchema from '../../../components/Schema/HomeSchema';
import {LoadingBoatList} from '../../../components/BoatList/LoadingBoatList';
import {getRouteConstantsFromI18n} from '../../../tppServices/translations/constants';
import { getMessages } from '../../../tppServices/translations/messages';
import { getPageAdsData } from '../../../utils/ads/adsUtils';
import { FEATURE_FLAG_KEYS, isFeatureFlagActive } from '../../../context/ABTestContext';

class MainContent extends PureComponent {
  state = {
    breakpoint: 'desktop'
  };

  constructor(props) {
    super(props);
    this.translationMessages = getMessages();
  }

  resizeHandler = () => {
    this.setState({
      breakpoint: getBreakpoint()
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  getSeoDataItems = (seoContent, type) => {
    let seoData = [];
    if (seoContent && seoContent.length > 0) {
      seoContent.forEach((seoItem) => {
        const listItem = seoItem.categories.find((category) =>
          category.includes(type)
        );
        if (listItem) {
          seoData.push({
            title: seoItem.title,
            html: seoItem.html,
            text: seoItem.text,
            id: listItem
          });
        }
      });
    }
    return seoData.sort((a, b) => {
      return a.id > b.id ? 1 : -1;
    });
  };

  getCustomUom() {
    const {
      cookies,
      intl: { locale }
    } = this.props;
    const isDefaultLocaleUom = get(this.context, 'supports.defaultUoms', false);
    const supportedUoms = get(this.context, 'supportedUoms');
    const defaultLocaleUom = get(
      this.context,
      `languages.${locale}.defaultUom`
    );
    return getCustomUom(
      isDefaultLocaleUom,
      cookies,
      supportedUoms,
      defaultLocaleUom
    );
  }

  parseEditorialItem(content) {
    return editContentAnchors(content, this.context.pages.home.seoConfig);
  }

  showForumSection() {
    const locale = getCurrentLocale();
    return get(this.context, `languages.${locale}.supportsForumHome`, false);
  }

  showFeaturedArticlesSection() {
    const locale = getCurrentLocale();
    return get(this.context, `languages.${locale}.showFeaturedArticles`, true);
  }

  render() {
    const {
      abTestContext,
      cookies,
      intl: { formatMessage: t },
      seoContent,
      privateFeaturedAds,
      blogSection,
      featuredBoat
    } = this.props;
    const messages = this.translationMessages;
    const customUom = this.getCustomUom();
    const context = get(this, 'context', {});
    const homeContext = context.pages.home;
    const portal = getPortalName(context);
    const maxPageSize = pageSizes[this.state.breakpoint];
    const seoInfo = this.getSeoDataItems(seoContent, 'seo-info');
    const quickListItems = this.getSeoDataItems(seoContent, 'quicklist');
    const targeting = getTargeting(get(this, 'context.name'));
    const pageAdsConfig = merge(homeContext.adsConfig, adsConfig);
    const showForumSection = this.showForumSection();
    const showFeaturedArticlesSection = this.showFeaturedArticlesSection();
    const quickSearchBlock = homeContext.quickSearchBlock;
    const featuredArticlesRow = homeContext.featuredArticlesRow;
    const onlyFeatured =
      homeContext?.adType === 'featured_boat' && quickSearchBlock;
    // featuredBoat has this name on the api. We could change the name if we wanted...
    const featuredBoats = featuredBoatsProps(featuredBoat, this.props.intl);
    const showFsboAd = get(homeContext, 'showFsboAd', false);
    const showSocialMediaLinks = get(
      homeContext,
      'showSocialMediaLinks',
      false
    );
    const quickLinksRedesign = get(homeContext, 'quickLinksRedesign', false);
    const buyingExperience = get(
      homeContext,
      'buyingExperienceCategories',
      false
    );
    const trackingInfo = {
      region: this.props.intl?.locale?.toUpperCase(),
      page: 1,
      portal: context?.name,
      pageName: 'home'
    };
    const noBoatListAds = get(this.context, 'supports.noBoatListAds', false);
    const routeConstants = getRouteConstantsFromI18n();

    const featureFlagAdButler = isFeatureFlagActive(
      FEATURE_FLAG_KEYS.AD_BUTLER,
      abTestContext?.featureFlags,
      cookies
    ) && get(this.context, 'supports.adButler', false);

    let newPageAdsConfig;
    if (featureFlagAdButler) {
      newPageAdsConfig = getPageAdsData(homeContext.newAdsConfig.adZones, this.props.adsData);
    }

    return (
      <>
        <AdProvider
          url={get(this.props, 'location.pathname', '')}
          isWorking={this.props.isWorking}
          targeting={targeting}
          adsConfig={pageAdsConfig}
        >
          <Meta
            canonical={getCanonical('/')}
            title={meta.getMetaTitle(portal)}
            description={meta.getMetaDescription(portal)}
          />
          <HomeSchema getLocale={getCurrentLocale} />
          <HeroViewer
            quickSearchBlock={quickSearchBlock}
            images={homeContext.heroImages}
            alt={t(messages.hero.heroImage)}
            ad={ featureFlagAdButler ? null : adParams.heroAd }
            premiumFeaturedAd={ featureFlagAdButler && newPageAdsConfig?.pfba ? newPageAdsConfig.pfba : null }
          />
          <QuickSearch
            quickSearchBlock={quickSearchBlock}
            adParams={adParams}
            featureFlagAdButler={featureFlagAdButler}
            box1Ad={featureFlagAdButler && this.state.breakpoint !== BREAKPOINTS.mobile && newPageAdsConfig['box-1'] ? newPageAdsConfig['box-1'] : null}
            customUom={customUom}
            cookies={this.props.cookies}
          />

          <FeaturedBoats
            featuredLabel={t(messages.featuredBoats)}
            featuredBoats={featuredBoats}
            wrapperClass={'main-content-body'}
            tracking={{
              setProductImpression: this.props.setProductImpression,
              trackingInfo
            }}
          />
          <div className="main-content-body">
            {!context.pages.home.hidden['fsbo-mini-ad'] && !onlyFeatured && (
              <div className="fsbo-mini-ad">
                {t(messages.fsboAd.fsboTitleShort)}
                <Link
                  href={routeConstants.SELL_URL_ROOT}
                  data-reporting-fsbo-internal-link-url="/home"
                  data-reporting-fsbo-internal-link-id="under search form"
                >
                  {t(messages.fsboAd.buttonShort)}
                </Link>
              </div>
            )}

            {this.state.breakpoint === BREAKPOINTS.mobile && (
              <>
                { !featureFlagAdButler && <Ad {...adParams.mobileBox1Params} /> }
                {
                  featureFlagAdButler && newPageAdsConfig['mobile-box-1']?.placement &&
                  <div id="mobile-box-1" className={newPageAdsConfig['mobile-box-1'].classes}>
                    <img alt={newPageAdsConfig['mobile-box-1'].placement.alt_text} src={newPageAdsConfig['mobile-box-1'].placement.image_url}></img>
                  </div>
                }
              </>
            )}

            {this.state.breakpoint !== BREAKPOINTS.mobile && (
              <>
                { !featureFlagAdButler && <Ad {...adParams.leaderboardMid1Params} /> }
                {
                  featureFlagAdButler && newPageAdsConfig['leaderboard-mid-1']?.placement &&
                  <div id="leaderboard-mid-1" className={newPageAdsConfig['leaderboard-mid-1'].classes}>
                    <img alt={newPageAdsConfig['leaderboard-mid-1'].placement.alt_text} src={newPageAdsConfig['leaderboard-mid-1'].placement.image_url}></img>
                  </div>
                }
              </>
            )}

            {!onlyFeatured && (
              <section className="latest-boats">
                <div className="main-content-element">
                  <h2 className="title">{t(messages.latestBoats)}</h2>

                  {!this.props.isWorking &&
                  this.props.search &&
                  this.props.search.records ? (
                    <BoatList
                      isHome={true}
                      adParams={adParams}
                      breakpoint={this.state.breakpoint}
                      mode={BoatList.MODES.home}
                      listings={this.props.search.records}
                      pos={{page: '1', pageSize: `${maxPageSize}`}}
                      labels={{
                        getTitle: getTitle,
                        getPrice: getPrice,
                        getLocation: getLocation
                      }}
                      intl={this.props.intl}
                      tracking={{
                        setProductImpression: this.props.setProductImpression,
                        trackingInfo
                      }}
                    />
                  ) : (
                    <LoadingBoatList number={maxPageSize}/>
                  )}
                  <a
                    className="view-all-boats"
                    href={generateSearchPath(
                      {},
                      {sort: 'updated:desc'},
                      true
                    )}
                  >
                    {t(messages.viewAllBoats)}
                  </a>
                </div>
              </section>
            )}

            {this.state.breakpoint === BREAKPOINTS.mobile && (
              <>
                { !featureFlagAdButler && <Ad {...adParams.mobileBox2Params} /> }
                {
                  featureFlagAdButler && newPageAdsConfig['mobile-box-2']?.placement &&
                  <div id="mobile-box-2" className={newPageAdsConfig['mobile-box-2'].classes}>
                    <img alt={newPageAdsConfig['mobile-box-2'].placement.alt_text} src={newPageAdsConfig['mobile-box-2'].placement.image_url}></img>
                  </div>
                }
              </>
            )}

            {this.state.breakpoint !== BREAKPOINTS.mobile && !noBoatListAds && (
              <>
                { !featureFlagAdButler && <Ad {...adParams.leaderboardMid2Params} /> }
                {
                  featureFlagAdButler && newPageAdsConfig['leaderboard-mid-2']?.placement &&
                  <div id="leaderboard-mid-2" className={newPageAdsConfig['leaderboard-mid-2'].classes}>
                    <img alt={newPageAdsConfig['leaderboard-mid-2'].placement.alt_text} src={newPageAdsConfig['leaderboard-mid-2'].placement.image_url}></img>
                  </div>
                }
              </>
            )}

            {showFsboAd && (
              <div className="main-content-element">
                <FSBOAd url={routeConstants.SELL_URL_ROOT}/>
              </div>
            )}

            {showForumSection && (
              <div className="main-content-element">
                <HomeForumSection/>
              </div>
            )}

            {privateFeaturedAds && privateFeaturedAds.length > 0 && (
              <section className="private-featured">
                <div className="main-content-element">
                  <h2 className="title">
                    {t(messages.featuredPrivateListings)}
                  </h2>
                  <PrivateFeaturedAds
                    device={this.state.breakpoint}
                    isLoading={this.props.isWorking}
                    cardItems={privateFeaturedAds}
                  />
                </div>
              </section>
            )}

            {showFeaturedArticlesSection &&
              get(blogSection, 'records', []).length > 0 && (
                <div className="main-content-element blog">
                  <HomeBlogSection
                    featuredArticlesRow={featuredArticlesRow}
                    blogSection={blogSection}
                  />
                </div>
              )}

            {buyingExperience && (
              <div className="main-content-element">
                <HomeBuyingExperience categories={buyingExperience}/>{' '}
              </div>
            )}

            {this.state.breakpoint === BREAKPOINTS.mobile && (
              <>
                { !featureFlagAdButler && <Ad {...adParams.mobileBox3Params} /> }
                {
                  featureFlagAdButler && newPageAdsConfig['mobile-box-3']?.placement &&
                  <div id="mobile-box-3" className={newPageAdsConfig['mobile-box-3'].classes}>
                    <img alt={newPageAdsConfig['mobile-box-3'].placement.alt_text} src={newPageAdsConfig['mobile-box-3'].placement.image_url}></img>
                  </div>
                }
              </>
            )}

            {this.state.breakpoint !== BREAKPOINTS.mobile && (
              <>
                { !featureFlagAdButler && <Ad {...adParams.leaderboardBottomParams} /> }
                {
                  featureFlagAdButler && newPageAdsConfig['leaderboard-bottom']?.placement &&
                  <div id="leaderboard-bottom" className={newPageAdsConfig['leaderboard-bottom'].classes}>
                    <img alt={newPageAdsConfig['leaderboard-bottom'].placement.alt_text} src={newPageAdsConfig['leaderboard-bottom'].placement.image_url}></img>
                  </div>
                }
              </>
            )}
          </div>

          {showSocialMediaLinks && (
            <SocialMedia
              appStoreLinks={get(context, 'banner.appStoreLink')}
              socialLinks={get(context, 'footer.subFooter.socialLinks', [])}
            />
          )}

          {(quickListItems.length > 0 || seoInfo[0]) && (
            <div className="information-content">
              {quickListItems.length > 0 && (
                <QuickLinks
                  breakpoint={this.state.breakpoint}
                  headerNumber={3}
                  content={quickListItems}
                  quickLinksRedesign={quickLinksRedesign}
                />
              )}
              {seoInfo[0] && (
                <EditorialItem
                  content={this.parseEditorialItem(seoInfo[0].html)}
                  classOverride={classnames('homepage-seo-content', {
                    'block-editorial': onlyFeatured
                  })}
                />
              )}
            </div>
          )}
        </AdProvider>
      </>
    );
  }
}

MainContent.propTypes = {
  abTestContext: PropTypes.object,
  adsData: PropTypes.any,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
    locale: PropTypes.string
  }).isRequired,
  isWorking: PropTypes.bool,
  search: PropTypes.shape({
    records: PropTypes.arrayOf(listingPropTypes)
  }),
  setProductImpression: PropTypes.func.isRequired,
  seoContent: PropTypes.array,
  privateFeaturedAds: privateFeaturedAdTypes,
  featuredBoat: PropTypes.array,
  cookies: PropTypes.instanceOf(Cookies),
  blogSection: PropTypes.shape({
    count: PropTypes.number.isRequired,
    records: PropTypes.array.isRequired
  })
};

MainContent.contextType = PortalConfigContext;
export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      setProduct,
      setProductImpression
    },
    dispatch
  )
)(injectIntl(MainContent));
