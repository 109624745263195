import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const AdSlot = ({adParams, adSlot}) => {
    if (Object.keys(adParams || {}).length === 0) {
      return null;
    }
    return (
      <div id={`ad-${adSlot}`} className={adParams[adSlot]?.classes}>
        <a className="adslot-target" href={adParams[adSlot]?.placement?.redirect_url} rel="noopener noreferrer" target="_blank">
          <img alt={adParams[adSlot]?.placement?.alt_text} src={adParams[adSlot]?.placement?.image_url} />
        </a>
      </div>
    );
  };

  AdSlot.propTypes = {
    adParams: PropTypes.object,
    count: PropTypes.number,
    adSlot: PropTypes.string,
  };

export default AdSlot;
