import { useState, useEffect } from 'react';

// TODO on TPP-5146: Confirm if this custom hook can be safely removed once the AdButler migration is complete.
const useShowAd = (adId) => {
  const [showAd, setShowAd] = useState(true);
  let adSlotEmpty = true;

  // This function is used to listen to the pubAds event to render the expected Ad.
  const adRenderingChecker = () => {
    // This function is used to check if the googletag.pubads() object is available.
    const googleTagPubAdsReady = () => {
      let countPubAdsCalls = 0;
      const pubAdsListener = (resolve) => {
        countPubAdsCalls++;
        if (typeof window?.googletag?.pubads !== 'undefined') {
          // googletag.pubads() object is available.
          resolve(true);
        } else if (countPubAdsCalls > 20) {
          // googletag.pubads() object is not available. after 20 calls === 2 seconds.
          resolve(false);
        } else {
          const callbackPubAdsListener = () => pubAdsListener(resolve);
          setTimeout(callbackPubAdsListener, 100); // will check if pubAds object is available every 100ms.
        }
      };

      return new Promise((resolve) => {
        pubAdsListener(resolve);
      });
    };
    // This function is used to check if there's an adblocker & wait for it.
    const adReady = () => {
      let count = 0;
      const adSlotListener = (resolve) => {
        count++;
        if (count < 25 && adSlotEmpty) {
          const callbackAdSlotListener = () => adSlotListener(resolve);
          setTimeout(callbackAdSlotListener, 100); // will check if pubAds object is available every 100ms.
        } else if (adSlotEmpty) {
          resolve(false);
        } else {
          resolve(true);
        }
      };
      return new Promise((resolve) => {
        adSlotListener(resolve);
      });
    };
    // This function is used to listen to the pubAds event to render the expected Ad.
    const pubAdsEventsListener = async () => {
      const pubAdsAvailable = await googleTagPubAdsReady();
      if (
        pubAdsAvailable &&
        typeof window.googletag.pubads().addEventListener === 'function'
      ) {
        window.googletag
          .pubads()
          .addEventListener('slotRenderEnded', (event) => {
            const slot = event.slot;
            if (slot.getSlotElementId() === adId) {
              // Ad slot has finished rendering.
              if (event.isEmpty) {
                setShowAd(false);
              } else {
                adSlotEmpty = false;
              }
            }
          });
        const showAd = await adReady();

        if (!showAd) {
          setShowAd(false);
        }
      } else {
        setShowAd(false);
      }
    };

    pubAdsEventsListener();
  };

  useEffect(() => {
    adRenderingChecker();
  }, []);

  return showAd;
};

export { useShowAd };
