import React from 'react';
import QuickSearchFilters from './QuickSearchFilters';
import PropTypes from 'prop-types';
import { Ad } from '@dmm/react-common-components';

const QuickSearchLayout = ({
  overlapLayout,
  messages,
  values,
  filterValues,
  layoutFlags,
  actions,
  adParams,
  featureFlagAdButler,
  box1Ad
}) => {
  const filterProps = { messages, values, filterValues, layoutFlags, actions };
  if (overlapLayout) {
    return <QuickSearchFilters {...filterProps} />;
  }
  return (
    <div className="qs-content-body">
      <div className="qs-block qs-content-element">
        <div id="qs-filters" className="qs-filters">
          <QuickSearchFilters layoutFlags={layoutFlags} {...filterProps} />
        </div>
        <div className="qs-ad-flex">
          <div className="qs-ad-content">
            <>
              { !featureFlagAdButler && <Ad {...adParams.box1Params} /> }
              {
                featureFlagAdButler && box1Ad?.placement &&
                <div id="box-1" className={box1Ad.classes}>
                  <img alt={box1Ad.placement.alt_text} src={box1Ad.placement.image_url}></img>
                </div>
              }
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

QuickSearchLayout.propTypes = {
  messages: PropTypes.object,
  actions: PropTypes.object,
  values: PropTypes.object,
  filterValues: PropTypes.object,
  layoutFlags: PropTypes.object,
  overlapLayout: PropTypes.bool,
  adParams: PropTypes.object,
  featureFlagAdButler: PropTypes.bool,
  box1Ad: PropTypes.object
};

export default QuickSearchLayout;
